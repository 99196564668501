<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';

const router = useRouter();
const { $t } = useNuxtApp();
</script>

<template>
  <div>
    <div class="mx-2">
      <div class="flex flex-col justify-center items-center flex-wrap mt-3">
        <div class="flex justify-center">
          <CheckCircleIcon class="w-16 text-primary-blue-600 stroke-2" />
        </div>
        <div>
          <p
            class="text-center my-4 line-height-3"
            v-html="$t('please-note-acc-unlocked')"
          />
        </div>
      </div>
    </div>
    <div class="bg-base dark:bg-layer-1 w-full px-2 py-1">
      <Button
        class="w-full my-2 justify-center"
        type="primary"
        @click="router.push('/')"
      >
        {{ $t('play-now') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
p {
  color: #2b303b;
  .dark & {
    color: #fff;
  }
}
</style>
